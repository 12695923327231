.filters {
  @apply py-10 xl:flex flex-row items-center text-blue font-semibold max-w-[1184px] mx-auto justify-between;
  & .tabs {
    @apply bg-grey-100 p-1 flex flex-row-reverse min-h-[66px] justify-end;
    & .tab {
      @apply opacity-100;
      transition: opacity .3s;
      & button {
        @apply text-[14px] py-[18px] border-none bg-transparent font-sans font-medium text-blue opacity-70 cursor-pointer relative block;
        line-Height: 22px;
        text-transform: none;
        min-width: 175px;
        &:after {
          @apply absolute content-[""] left-[2px] bottom-1 h-1 block bg-transparent;
          z-index: 2;
          width: calc(100% - 4px);
        }
      }
      &.active {
        & > button {
          @apply opacity-100 bg-white;
          &:after {
            @apply bg-red;
          }
        }
      }
      &.disabled {
        @apply opacity-30;
        & > button {
          cursor: initial;
        }
      }
    }
    &.is-loading {
      & .tab {
        @apply opacity-0;
      }
    }
  }
  & .options {
    @apply md:flex flex-row items-center;
    & .filter-button {
      @apply border border-grey-400 rounded-[18px] cursor-pointer relative py-1 px-4 ml-5 mr-5 mb-2 md:mb-0;
    }
    & .label {
      @apply block py-1 ml-5 mb-2 md:mb-0;
    }
    .filter-checkbox {
      @apply m-0 ml-5 md:ml-2 h-9 w-[60px] rounded-[18px] bg-grey-150 relative cursor-pointer;
      & .icon {
        @apply absolute w-[30px] h-[30px] bg-white rounded-[50%] border-2 border-grey-100 flex flex-row items-center justify-center top-[3px] left-[3px];
        transition: all .3s;
        & svg {
          @apply opacity-0;
          transition: opacity .3s;
        }
      }
      & input {
        @apply m-0 w-full h-full opacity-0 relative cursor-pointer z-10;
        &:checked ~ div {
          @apply border-blue;
          left: calc(50% - 3px);
          & svg {
            @apply opacity-100;
          }
        }
      }
    }
  }
}

.container .results {
  @apply mx-auto max-w-[1184px];
}
.map-container {
  @apply px-4;
  & .results {
    @apply max-w-full;
    & > div {
      @apply w-1/2;
    }
    & .properties {
      & span {
        display: none;
      }
    }
  }
}

.search-bar {
  @apply block lg:mt-[-62px] rounded-[12px] px-[18px] py-[20px] lg:p-0 lg:pr-[15px] md:flex flex-row flex-wrap lg:flex-nowrap items-center justify-between bg-grey-100 shadow-[0_0_12px_6px_rgba(0,0,0,0.1)] relative z-10 w-[95%] max-w-[1315px] m-auto;

  & .title {
    @apply hidden lg:block bg-teal-200 w-[133px] p-2 relative text-white;
    & h3 {
      @apply p-[1.2rem] border border-white font-sans uppercase font-semibold text-white text-[1.1rem] leading-[1.4rem];
    }
    & svg {
      @apply block absolute w-[13px] top-1/2 -translate-y-1/2 right-[1.2rem];
    }
  }
  & .field {
    @apply relative md:px-[10px] pt-[8px] lg:pt-0 border-t border-t-grey-300 md:border-t-0 md:w-1/2 lg:w-auto;
    &.spacer {
      @apply hidden lg:block px-[10px];
      & svg {
        @apply w-[11px];
        max-height: 100%;
      }
    }
    & .label {
      @apply uppercase font-sans text-grey-500 font-semibold text-xs translate-y-[2px] origin-top-left;
    }
    & h3 {
      @apply text-xl text-blue pt-[2px] px-0 pb-[8px] md:border-b border-b-blue cursor-pointer leading-7;
    }
    &.actions {
      @apply pt-4 lg:pt-0 text-center;
      & a {
        @apply block mt-[9px] font-semibold text-teal-200 text-sm
      }
    }
    & .DateRangePickerInput, & .DateInput, & .DateInput_input {
      @apply bg-transparent font-serif text-2xl text-blue;
    }
    & .DateInput_input {
      @apply placeholder-blue pt-[2px] pb-[8px] px-0 md:px-[11px] border-b-transparent border-b md:border-b-blue font-display text-xl;
    }
    &.select {
      & .type-select {
        & > li {
          @apply py-4 block text-blue font-display cursor-pointer font-normal border-t border-t-gray-300 text-center text-lg;
          transition: all .3s;
          &.selected, &:hover {
            @apply text-white bg-teal-200;
          }
        }
      }
      & .selection {
        & h3 {
          @apply min-w-[126px] flex flex-row justify-between items-center;
          & > svg {
            @apply ml-4;
          }
        }
      }
    }
  }
}

.filter-button, .filter-button > span {
  display: flex;
  flex-direction: row;
  align-items: center;
  & svg {
    width: 0.75em;
    margin-left: 0.25rem;
  }
}


.guests-option {
  & .add-room {
    & > div {
      & > svg {
        @apply left-1/2 -translate-x-1/2 relative;
      }
    }
  }
}

.api-room {
  @apply bg-white my-8 p-4 shadow-[0_0_6px_3px_rgba(0,36,63,0.1)];
  & h3 {
    @apply text-2xl text-blue mb-4 flex flex-row justify-between;
    & span {
      & span {
        @apply text-base font-normal font-sans;
      }
    }
  }
  & .api-room-options {
    @apply flex flex-row justify-between items-center;
  }
}
.calendar-icon {
  @apply ml-2 text-xl;
  > svg {
    height: 20px;
  }
}
