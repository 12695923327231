
.what-we-love {
  @apply relative lg:mb-[150px];
  & .image {
    @apply relative w-full bg-cover xl:max-w-[795px];
    height: 540px;
  }
  & .panel {
    @apply bg-white md:absolute bottom-0 max-w-[513px];
    & .panel-inner {
      @apply p-8 xl:p-[48px_32px_32px_64px];
      & .heading {
        @apply relative pb-4 font-serif font-normal text-[2rem] lg:text-[2.25rem] leading-[2.5rem] lg:leading-[3rem] text-blue;
        & .tagLarge {
          @apply text-teal-200 font-sans font-semibold text-base leading-[1.1rem] uppercase tracking-[2px] block;
        }
        &:after {
          @apply bg-red w-[5.2rem] h-[2px] block absolute left-0 bottom-0 content-[""];
        }
      }
      & .inset-statement {

      }
    }
  }
  &.left {
    & .image {
      @apply ml-auto;
    }
    & .panel {
      @apply left-0;
    }
  }
  &.right {
    & .image {
    }
    & .panel {
      @apply right-0;
    }
  }
}
@screen md {
  .what-we-love {
    &.left {
      & .image {
        border-bottom: 35px solid #f2e5d2;
        border-left: 35px solid #f2e5d2;
      }
    }
    &.right {
      & .image {
        border-bottom: 35px solid #f2e5d2;
        border-right: 35px solid #f2e5d2;
      }
    }
  }
}
