.intro-images {
  @apply relative;
  & span {
    &:first-child {
      @apply block shadow-[1.45rem_-1.45rem_0_0] shadow-teal-100 mt-0 mr-auto mb-[98px] md:mb-[200px] ml-auto max-w-[172px] max-h-[287px] md:max-w-[278px] md:max-h-[464px] !important;
    }
    &:nth-child(2) {
      @apply absolute left-0 top-[60%] w-[144px] h-[144px] sm:top-[80%] sm:w-[253px] sm:h-[253px] sm:left-[-20px] md:left-[40px] !important;
    }
  }
  & svg {
    @apply absolute top-[80%] w-[115px] sm:w-[160px] left-[calc(50%_+_18px)] sm:left-[calc(100%_-_115px)] md:hidden lg:block lg:left-[calc(100%_-_80px)];
    max-height: 100%;
  }
}
