@font-face {
  font-family: DomaineNarrow;
  src: url("/static/fonts/domaine-display-narrow-web-semibold.eot");
  src: url("/static/fonts/domaine-display-narrow-web-semibold.eot?#iefix") format('embedded-opentype'),
  url("/static/fonts/domaine-display-narrow-web-semibold.woff2") format('woff2'),
  url("/static/fonts/domaine-display-narrow-web-semibold.woff") format('woff');
  font-weight: 500;
  font-display: swap;
}
@font-face {
  font-family: Domaine;
  src: url("/static/fonts/domaine-display-web-regular.eot");
  src: url("/static/fonts/domaine-display-web-regular.eot?#iefix") format('embedded-opentype'),
  url("/static/fonts/domaine-display-web-regular.woff2") format('woff2'),
  url("/static/fonts/domaine-display-web-regular.woff") format('woff');
  font-weight: 400;
  font-display: swap;
}

body {
  @apply text-sm text-grey-500;
  font-family: Montserrat,sans-serif;
  font-size: 14px;
  font-weight: 300;
  line-height: 24px;
}
svg {
  max-height: 28px;
  display: inline-block;
}
.DayPickerNavigation_button svg {
  height: 16px;
}
