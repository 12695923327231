header {
  @apply fixed w-full bg-white border-b border-b-blue flex flex-row z-20;
  & .mainNavigation {
    @apply flex-grow;
    & .mainMenu {
      & .menu {
        & ul {
          @apply flex flex-row;
        }
      }
    }
  }
  & .logoWrapper {
    @apply bg-blue lg:bg-white w-[143px] md:w-2/6 md:w-1/5 p-4 flex flex-row items-center justify-center cursor-pointer;
    & a {
      @apply block w-full h-full
    }
    & svg {
      @apply block text-white lg:text-blue h-auto w-full lg:h-[53px] max-h-[53px];
    }
  }
  & .logo-wrapper {
    & a {
      @apply text-blue;
    }
  }
  & .nav-item {
    @apply w-1/5 border-l border-l-grey-300 text-[1.125rem] lg:text-base xl:text-[1.125rem];
    line-height: 1.375rem;
    text-align: center;
    position: relative;
    padding: 3px;
    & > a, & > span {
      @apply block cursor-pointer border-b border-b-[3px] border-transparent text-blue relative;
      padding: 15px 0;
      font-family: Domaine, serif;
      font-weight: 600;
      position: relative;
      & > svg {
        @apply h-4 ml-2;
      }
      &:after {
        @apply content-[''] block bg-red h-[3px] top-full left-1/2 w-full absolute scale-0 -translate-x-1/2;
        transition: all 0.3s;
      }
      &:hover {
        &:after {
          @apply scale-100;
        }
      }
    }
    &.active {
      & > a, & > span {
        &:after {
          @apply scale-100;
        }
      }
    }
    & .subMenu {
      @apply flex-col !important;
      & li {
        @apply border-t border-t-grey-300;
        & a {
          @apply block py-4 px-0 font-serif font-medium text-blue
        }
      }
    }
  }
  & .topNav {
    @apply bg-blue flex flex-row;
    font-size: 0.8rem;
    line-height: 1rem;
    padding: 0.55rem 1.3rem;
    & > nav {
      @apply flex flex-grow flex-row justify-end;
      & ul {
        @apply flex flex-row;
        & > li {
          @apply text-white flex mr-4 lg:mr-8 relative items-center font-light;
          & > span {
            @apply hidden lg:inline;
          }
          &:last-child, &:nth-child(6) {
            & > span {
              @apply hidden;
            }
          }
          &:last-child {
            @apply mr-0;
          }
          & > a {
            @apply text-white;
          }
          & > svg {
            @apply inline-block text-white h-[20px] w-[20px] mr-[10px] !important;
          }
        }
      }
    }
  }
  & .language-select-wrapper {
    @apply lg:flex-grow relative;
    & .language-select {
      @apply flex items-center cursor-pointer;
      & svg {
        @apply text-blue lg:text-white h-[20px] w-[20px] mr-[10px];
      }
    }
  }
  & .toggleNav {
    @apply border-l border-l-grey-300  relative flex flex-col justify-center items-center w-1/4 xl:w-auto xl:px-[22px];
  }
  & .hamburger {
    @apply relative flex flex-col justify-around w-8 h-8 bg-transparent cursor-pointer z-10;
    &:focus {
      outline: none;
    }
    & span {
      @apply w-8 h-1 bg-blue rounded-[10px] relative;
      transition: all 0.3s linear;
      transform-origin: 1px;
      &:first-child {
        transform: rotate(0);
      }
      &:nth-child(2) {
        opacity: 1;
        transform: translateX(0);
      }
      &:nth-child(3) {
        transform: rotate(0);
      }
    }
    &.hamburgerOpen {
      span {
        &:first-child {
          transform: rotate(45deg) translateX(4px) translateY(-4px);
        }
        &:nth-child(2) {
          opacity: 0;
          transform: translateX(20px);
        }
        &:nth-child(3) {
          transform: rotate(-45deg) translateX(4px) translateY(4px);
        }
      }
    }
  }
}
.basket {
  @apply cursor-pointer;
  > span {
    @apply hidden;
  }
  &.badge {
    &:after {
      @apply absolute right-[-4px] top-[-6px] content-[attr(data-count)] text-center bg-red text-white rounded-full p-[.2rem] min-w-[1.25rem] text-xs;
    }
  }
}

.login {
  cursor: pointer;
  & svg {
    margin-right: 10px !important;
    display: inline-block !important;
    height: 20px !important;
    width: 20px !important;
  }
}
.mainMobileNavigation {
  @apply flex flex-col-reverse;
  & .mainMenu {
    & .menu {
      & ul {
        & .nav-item {
          @apply w-full border-t-blue border-t border-l-0;
        }
      }
    }
  }
  & .topNav {
    @apply bg-white px-0 pt-0;
    & .secondaryNav {
      @apply block border-t border-t-blue pt-4;
      & ul {
        @apply flex-wrap;
        & li {
          @apply font-medium mb-4 w-[44%];
          font-size: .9rem;
          line-height: 1.2rem;
          text-transform: initial;
          & svg {
            @apply text-grey-500 !important;
          }
          & a {
            @apply pl-4 text-grey-500;
            &[href="/account/wishlist"] {
              @apply pl-0;
            }
          }
          &:last-child, &:nth-child(6), &.login {
            & span {
              @apply inline-block text-grey-500 font-medium;
            }
          }
          &.login {
            @apply pl-4;
          }
          &.search {
            @apply w-full flex-grow border-t-blue border-t pt-4 pl-4;
          }
        }
      }
    }
  }
}
