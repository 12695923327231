.hero {
  @apply overflow-hidden relative w-full xl:h-screen;
  max-height: calc(100vh - 86px);
  & .video-background, & .image-background {
    @apply w-full h-full relative bg-cover pb-[56%];
    background-position: 50%;
    & video {
      @apply w-full absolute;
    }
    & img {
      @apply w-full h-full;
    }
  }
  & .hero-inner {
    @apply absolute z-10 text-white top-[30%] md:top-[20%] left-[10%];
    & h1 {
      @apply mb-12 text-[30px] md:text-[50px] font-sans font-normal text-white;
      line-height: 1;
      & span {
        @apply block;
        &:last-child {
          @apply font-display text-[40px] md:text-[86px] font-bold mt-[10px] md:mt-[20px];
        }
      }
    }
  }
  &.overlay {
    & .video-background, & .image-background {
      @apply before:w-full before:content-[""] before:h-full before:absolute before:top-0 before:left-0 before:bg-black/40 before:z-[1];
    }
  }
  & .weather-stats {
    @apply hidden md:flex flex-row justify-start items-center;
    & > div {
      @apply border-l border-l-[2px] border-l-white px-[29px];
      & svg, & img {
        @apply w-6 h-6;
      }
      & h4 {
        @apply text-[1.05rem] font-normal text-white font-sans;
      }
      & span {
        font-size: 1.5rem;
        line-height: 29px;
        font-weight: 500;
      }
    }
  }
  &.product, &.destinations {
    height: calc(100vh - 160px);
    & .hero-inner {
      max-width: 900px;
    }
    & .stars {
      & svg {
        @apply inline-block w-6 h-6 mr-4 opacity-50;
      }
      & .active {
        & svg {
          @apply opacity-100;
        }
      }
    }
    & .tags {
      @apply absolute left-[10%] z-10 bottom-8;
      li {
        @apply text-green-200 h-[30px] rounded-2xl border border-green-200 bg-white px-3 inline-block mr-3 text-xs font-normal;
        line-height: 28px;
      }
    }
  }
  &.mallorca {
    @apply h-[300px] lg:h-screen;
  }
}
.meta-header {
  @apply relative text-center bg-blue flex flex-col items-center justify-center p-8 md:p-0;
  min-height: calc((200 / 1440) * 100vw);
  & > span > img {
    @apply absolute w-full object-cover top-0 opacity-80 h-full;
  }
  & h1 {
    @apply text-white relative font-display text-4xl;
  }
  & p {
    @apply text-white relative font-sans text-base xl:text-[20px] md:pt-4;
  }

  &.dark {
    h1, p {
      @apply text-blue;
    }
  }
}

@screen md {
  .hero {
    max-height: calc(100vh - 95px);
  }
}
@screen lg {
  .hero {
    max-height: calc(100vh - 100px);
    & .hero-inner {
      & h1 {
        & span {
          &:last-child {
            line-height: 90px;
          }
        }
      }
    }
    &.product {
      max-height: calc(100vh - 186px);
    }
  }
}
@media screen and (min-width: 1024px) and (max-height: 800px) {
  .hero .hero-inner {
    top: 10%;
  }
}
.page-header {
  @apply overflow-hidden relative bg-grey-100;
  padding: 112px 0px;
  & > svg {
    height: 100%;
    display: block;
    position: absolute;
    top: 0;
    margin: auto;
    left: 50%;
    transform: translateX(-50%);
    z-Index: 1;
    opacity: 0.4;
    max-height: initial;
  }
  & > img {
    height: 100%;
    width: 100%;
    display: block;
    position: absolute;
    top: 0;
    margin: auto;
    left: 50%;
    transform: translateX(-50%);
    z-Index: 1;
    max-height: initial;
  }
  .page-title {
    @apply after:bg-red;
    font-size: 3rem;
    line-height: 60px;
    font-weight: 600;
    padding-bottom: 16px;
    position: relative;
    text-align: center;
    z-index: 2;
    &:after {
      display: block;
      content: "";
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      width: 83px;
      height: 2px;
    }
  }
  .page-subtitle {
    position: relative;
    text-align: center;
    z-index: 2;
  }
}
