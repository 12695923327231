.DateInput_1 {
    width: 142px;
}
.bookingHover .DateInput {
    width: 94px;
}
.bookingHover .bookingInnerHover .DateInput {
    width: 68px;
}
.bookingHover .SingleDatePickerInput {
    background: none;
}
.bookingHover .DateRangePickerInput span {
    width: 17px;
}
.selectAvailabilityDate .DateInput {
    width: 108px;
}
.selectAvailabilityDate .DateInput_input {
    border: none;
    font-size: 13px;
    font-weight: 600;
    font-family: "Montserrat", sans-serif;
    padding: 6px 11px;
}
.DayPickerNavigation_button__default {
    border: none;
}
.CalendarMonth_caption_1 {
    color: #00243F;
    font-family: Domaine, serif;
    margin-top: 15px;
    padding-top: 0.5rem;
    margin-bottom: 30px;
    padding-bottom: 0.5rem;
    border: 1px solid #979797;
    border-left: none;
    border-right: none;
}
.CalendarMonth_caption_1 strong {
    font-weight: 500;
}
.DayPickerNavigation.DayPickerNavigation_1.DayPickerNavigation__horizontal.DayPickerNavigation__horizontal_2 .DayPickerNavigation_button {
    position: absolute;
}
.DayPickerNavigation.DayPickerNavigation_1.DayPickerNavigation__horizontal.DayPickerNavigation__horizontal_2 .DayPickerNavigation_button:first-child {
    top: 23px;
    line-height: .78;
    border-radius: 3px;
    padding: 6px 9px;
    left: 20px;
}
.DayPickerNavigation.DayPickerNavigation_1.DayPickerNavigation__horizontal.DayPickerNavigation__horizontal_2 .DayPickerNavigation_button:last-child {
    top: 23px;
    line-height: .78;
    border-radius: 3px;
    padding: 6px 9px;
    right: 20px;
}
.DateRangePickerInput {
    @apply bg-transparent;
}
.DateInput_input__focused, .DateInput_input, .DateInput {
    @apply font-serif text-blue text-[1.4rem] leading-[1.5rem];
    background: transparent;
    border-bottom-color: transparent;

}
.DateRangePicker_picker {
    z-index: 3;
}
.CalendarDay__selected, .CalendarDay__selected:active, .CalendarDay__selected:hover {
    background: #7BCAD2;
}
.CalendarDay__selected_span, .CalendarDay__selected_span:active, .CalendarDay__selected_span:hover {
    @apply rounded-none text-white !important;
    background: #7BCAD2;
}
.CalendarDay__blocked_out_of_range, .CalendarDay__blocked_out_of_range:active, .CalendarDay__blocked_out_of_range:hover, .CalendarDay__default {
    @apply border-transparent rounded-full;
}
.CalendarDay__selected_start {
    @apply rounded-r-none text-white;
    background: #00243F !important;
}

.CalendarDay__selected_end {
    @apply rounded-l-none text-white;
    background: #00243F !important;
}
.CalendarDay__selected_span:active, .CalendarDay__selected_span:hover, .CalendarDay__selected, .CalendarDay__selected:active, .CalendarDay__selected:hover {
    border: none !important;
}
.CalendarDay__default {
    @apply font-semibold;
}
.CalendarDay__default:not(.CalendarDay__blocked_out_of_range):hover {
    background: #7BCAD2;
    border: none;
}
.CalendarDay__blocked_calendar {
    border-radius: 0;
    opacity: .3;
}
.CalendarDay__blocked_calendar:hover {
    background: #cacccd !important;
    cursor: default;
}
