.faqs-section {
  & h2 {
    @apply text-4xl text-blue border-b border-b-blue pb-4 mb-8;
  }
  & .faq-questions {
    & h3 {
      @apply bg-grey-100/50 p-4 text-xl text-blue cursor-pointer flex flex-row items-center justify-between border-b-white border-b-4;
      & svg {
        @apply w-3;
        transition: transform 0.3s;
      }
      &.active {
        @apply bg-grey-100;
        & svg {
          transform: rotate(90deg);
        }
      }
    }
  }
  &.active {

  }
}
