html {
  scroll-behavior: smooth;
}
main.wrapper {
  @apply pt-[72px] md:pt-[95px] min-h-screen lg:pt-[100px];
}
img {
  object-fit: cover;
}

.profile-image {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 1px solid white;
  margin-right: 10px;
  overflow: hidden;
  display: inline-block;
}

.page-wrapper {
  @apply my-[5.25rem];
}
h1,h2,h3,h4,h5,h6 {
  @apply font-display text-blue;
}
p {
  @apply my-4;
}
.loading {
  @apply text-center w-full uppercase font-semibold text-blue flex flex-col md:flex-row items-center justify-center py-8;
  & svg {
    @apply w-[15%] m-auto;
  }
  & p {
    @apply my-6 md:mx-8;
  }
  & .loader-wrap {
    @apply rounded-full bg-white w-24 h-24 flex flex-col justify-center items-center;
  }
}
.about-us-hero {
  & svg {
    @apply mx-auto;
  }
}
.red-square {
  @apply max-w-[362px];
  & h2 {
    @apply text-white !important;
  }
}
.about-us-content {
  & h3 {
    font-weight: 700;
  }
}
.inset-panel_tagLarge {
  font-weight: 700;
}
.about-info {
  & p {
    @apply my-4;
  }
}
.brand-card-title, .wellness-holiday h2 {
  @apply text-white;
}
.menu-item > span {
  //display: flex !important;
  //flex-direction: row;
  //align-items: center;
  & svg {
    display: inline-block;
    width: 0.75em;
    margin-left: 0.25rem;
  }
}


strong {
  @apply font-bold;
}
.chevron-right, .chevron-left {
  width: 50px;
  background: none;
  border: 0;
  position: relative;
  padding: 0 15px;
  outline: none;
  transition: transform .2s ease-out;
  cursor: pointer;
  color: white;
  &.disabled {
    display: none;
  }
  & svg {
    font-size: 18px;
    margin-right: 8px;
  }
}

.chevron-left {
  transform: rotate(180deg);
}

.modal {
  @apply fixed w-full h-full z-50 top-0 left-0 bg-blue/40 flex flex-col justify-center items-center;
  & .modalInner {
    @apply bg-white w-[90%] max-w-[700px] rounded-[10px] max-h-[90vh];
    box-shadow: 0 0 12px 6px rgb(0 0 0 / 10%);
  }
  & .modalHeader {
    @apply p-2 min-h-[32px] relative flex flex-row items-center justify-between border-b border-b-grey-300;
    & h2 {
      @apply text-blue py-1 px-5 text-xl font-semibold font-display;
    }
  }
  & .modalContent {
    overflow-y: auto;
    height: calc(100% - 53px);
    & .overview {
      @apply h-full overflow-y-auto p-8;
      & h2 {
        @apply font-display text-blue text-[28px] mt-7;
      }
      & p {
        @apply my-4;
      }
      & img {
        width: 100%;
        max-height: 350px;
      }
    }
  }
  & .popupClose {
    @apply absolute right-4 w-8 h-8 cursor-pointer bg-transparent border-none;
    &:before, &:after {
      @apply absolute h-6 bg-blue w-[2px] top-[4px] left-[16px];
      content: " ";
    }
    &:before {
      transform: rotate(45deg);
    }
    &:after {
      transform: rotate(-45deg);
    }
  }
}
.additional-info-panel {
  @apply pb-4;
}

.sectionHeading {
  @apply text-blue text-4xl leading-[3rem];
  &.centered {
    @apply text-center;
  }
  & span {
    @apply text-teal-200 font-sans font-semibold uppercase text-base leading-[1.1rem] tracking-wide block;
  }
}
.has-registered {
  @apply py-4 px-8;
  & p {
    @apply my-4 mx-0;
    & span {
      color: #016D81;
      text-decoration: underline;
      font-weight: 600;
      margin-left: 12px;
      cursor: pointer;
    }
  }

}

.more-info-required {
  @apply mt-4 p-4 bg-red text-white text-center;
  & > svg {
    max-width: 48px;
    margin: auto;
  }
}

.no-availability {
  @apply mt-[40px] text-center max-w-[600px] mx-auto pb-8;
  p {
    @apply text-center font-sans text-xl;
  }
}

.shop-wrapper {
  padding: 5.65rem 0;
  background: #f2f2f2;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.shop-heading {
  font-size: 50px;
  line-height: 50px;
  margin: 0;
  font-family: DomaineNarrow,serif;
  & span {
    color: #016d81;
    font-family: Montserrat,sans-serif;
    display: block;
    font-weight: 600;
    text-transform: uppercase;
    font-size: 24px;
  }
}
.shop-icon {
  margin-top: 2rem;
  & svg {
    height: 16px;
    display: block;
  }
}
.shop-body {
  font-size: 16px;
  line-height: 24px;
  margin: 1rem 0;
}
.shop-image {
  @apply relative w-full min-h-[300px] lg:h-[590px];
}
.wt-loader_svg__ring {
  animation: loading 4s linear infinite;
  transform-origin: center;
}
@keyframes loading {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}

.category-tag {
  @apply inline-block text-red text-xs font-medium bg-[#d2eaec] rounded-2xl p-2;
}
.discover-intro-inner, button {
  & svg {
    max-height: initial;
  }
}
.account-navigation {
  @apply flex lg:hidden font-semibold;
  margin-top: 60px;
  flex-direction: row;
  justify-content: space-between;
  li {
    a {
      text-decoration: underline;
    }
    & + li {
      padding: 0 0.25rem;
      /* border-left: 1px solid #7BCAD2; */
    }
    &:first-child {
      padding-right: 0.25rem; 
    }
    &:last-child {
      padding-right: 0;
    }
  }
}
.communityRating {
  svg {
    width: 190px;
    max-height: 100%;
  }
}
.body-content {
  h2 {
    @apply text-2xl font-serif;
  }
}
dialog {
  @apply bg-white w-[90%] max-w-[700px] rounded-[10px] max-h-[90vh] p-0;
  box-shadow: 0 0 12px 6px rgb(0 0 0 / 10%);
  & .modalInner {
    
  }
  & .modalHeader {
    @apply p-2 min-h-[32px] relative flex flex-row items-center justify-between border-b border-b-grey-300;
    & h2 {
      @apply text-blue py-1 px-5 text-xl font-semibold font-display;
    }
  }
  & .modalContent {
    @apply p-4;
    overflow-y: auto;
    height: calc(100% - 53px);
  }
  & .popupClose {
    @apply absolute right-4 w-8 h-8 cursor-pointer bg-transparent border-none;
    &:before, &:after {
      @apply absolute h-6 bg-blue w-[2px] top-[4px] left-[16px];
      content: " ";
    }
    &:before {
      transform: rotate(45deg);
    }
    &:after {
      transform: rotate(-45deg);
    }
  }
}
.video-wrapper {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  height: 0;
  iframe, video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}