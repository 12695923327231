.card {
  @apply flex bg-white mb-9;
  &.landscape {
    @apply flex-row;
    & .gallery {
      @apply lg:w-1/2;
      & .slider-container {
        @apply md:h-[396px];
        & .slider-frame, & .slider-list {
          @apply h-full !important;
        }
        & .image {
          @apply h-full;
          & img {
            @apply h-full max-h-[445px] w-full;
          }
        }
      }
    }
    & .card-content {
      @apply py-6 px-[5%] w-1/2 flex flex-col;
      & .card-header {
        @apply flex flex-row items-center mb-2;
        & .card-icon {
          @apply w-[42px] mr-[18px];
          & svg {
            width: 42px;
          }
        }
        & .tagline {
          @apply uppercase text-xs mr-4;
          line-height: 16px;
        }
        & .rating {
          @apply flex flex-row;
          & svg {
            @apply mr-2;
          }
        }
      }
      & h2 {
        @apply text-[30px] mb-4 font-bold text-blue;
        font-size: 30px;
        line-height: 38px;
      }
      & .card-middle {
        @apply flex-grow;
        & .details {
          @apply flex flex-row items-center mb-[10px];
          & li {
            @apply mr-[6%];
            & span {
              @apply min-w-[50px] text-red font-semibold inline-block after:content-[":"];
            }
          }
        }
        & .card-overview {
          @apply mb-6 text-sm relative;
          & > p {
            line-height: 22px;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            overflow: hidden;
          }
          & > span {
            @apply block absolute bg-white bottom-[1px] right-0;
            background: white;
            & strong {
              @apply font-bold text-blue cursor-pointer;
            }
          }
        }
        & .properties {
          @apply flex flex-row text-[10px] items-center justify-between pb-[24px] font-medium;
          & img {
            @apply w-8;
          }
          &.no-spacing {
            @apply justify-start;
            & span {
              @apply mx-2;
            }
          }
        }
      }
      & .card-footer {
        @apply border-t border-t-grey-150 pt-[10px] flex flex-row justify-between items-center;
        & .actions {
          @apply flex flex-row justify-end items-center;
          & svg {
            @apply mr-4 cursor-pointer;
          }
        }
        & .price {
          & .label {
            @apply block font-display text-green-200 text-[13px];
          }
          & .amount {
            @apply font-extrabold text-xl text-blue;
          }
        }
      }
    }
    & .card-inner {
      @apply md:h-[396px] !important;
      & .card-inner-wrapper {
        @apply flex flex-col h-full;
        & h2 {
          @apply font-bold text-blue text-[30px] leading-[36px] !important;
        }
      }
    }
    &.hotel {
      & .card-inner {
        @apply md:h-[396px] !important;
      }
    }
    &.experience {
      & .gallery {
        & .slider-container {
          @apply md:h-[384px];
        }
      }
      & .card-inner {
        @apply md:h-[384px] !important;
      }
    }
  }
  & .properties-label {
    line-height: 12px;
    width: 60px;
  }
  &.portrait {
    @apply flex-col w-[326px];
    & .gallery {
      @apply w-full;
      & .slider-container {
        @apply h-[217px];
        & .image {
          @apply h-full;
          & img {
            @apply h-full max-h-[217px] w-full;
          }
        }
      }
    }
    & .card-content {
      @apply py-4 px-6 w-full flex flex-col flex-grow;
      & .card-header {
        @apply flex flex-row items-center mb-2;
        & .card-icon {
          @apply w-[32px] mr-[18px];
          & svg {
            width: 32px;
          }
        }
        & .tagline {
          @apply uppercase text-xs mr-4;
          line-height: 16px;
        }
        & .rating {
          @apply flex flex-row;
          & svg {
            @apply mr-2;
          }
        }
      }
      & h2 {
        @apply text-[24px] mb-4 font-bold text-blue;
        line-height: 32px;
      }
      & .card-middle {
        @apply flex-grow flex flex-col;
        & .details {
          @apply flex flex-col mb-[10px];
          & li {
            @apply mb-2;
            & span {
              @apply min-w-[50px] text-red font-semibold inline-block after:content-[":"];
            }
          }
        }
        & .card-overview {
          @apply mb-6 text-sm relative flex-grow;
          & > p {
            line-height: 22px;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            overflow: hidden;
          }
          & > span {
            @apply block absolute bg-white top-[45px] right-0;
            background: white;
            & strong {
              @apply font-bold text-blue cursor-pointer;
            }
          }
        }
        & .properties {
          @apply flex flex-row text-[10px] items-center justify-between pb-[24px] font-medium;
          & img {
            @apply w-8;
          }
          & span {
            display: none;
          }
        }
      }
      & .card-footer {
        @apply border-t border-t-grey-150 pt-[10px] flex flex-row justify-between items-center;
        & .actions {
          @apply flex flex-row justify-end items-center;
          & svg {
            @apply mr-4 cursor-pointer;
          }
        }
        & .price {
          & .label {
            @apply block font-display text-green-200 text-[13px];
          }
          & .amount {
            @apply font-extrabold text-xl text-blue;
          }
        }
      }
    }
  }
  & h2 {
    @apply font-semibold text-blue;
  }
  &.vertical {
    @apply max-w-[350px] md:max-w-full;
    & .card-inner {
      padding: 10px 16px 16px !important;
    }
    & h2 {
      @apply text-[24px] leading-[28px] font-bold !important;
    }
    & .card-inner {
      @apply h-full !important;
      & .card-inner-wrapper {
        @apply flex flex-col h-full;
      }
    }
    & .gallery {
      width: 100% !important;
    }
  }
  & .gallery {
    & .slider-control-centerright {
      right: 0 !important;
    }
    & .slider-control-centerleft {
      left: 0 !important;
    }
  }
}

.seek-card {
  @apply max-w-[228px] mx-auto my-0 md:flex md:flex-row;
  & .card-inner {
    @apply relative overflow-hidden;
    & > span > img {
      @apply w-full block scale-100 transition-all duration-[600ms]
    }
    .card-content {
      @apply top-0 left-0 text-white w-full cursor-default h-full flex absolute items-center flex-col justify-center;
      & h3 {
        @apply text-white font-sans uppercase text-2xl font-medium text-center;
        line-height: 2.25rem;
      }
      & .card-extra {
        @apply opacity-100 h-[60px] md:opacity-0 md:h-0 overflow-hidden transition-all duration-[600ms] pt-1;
        & hr {
          @apply w-8 border-0 mb-4 h-[2px] mx-auto bg-white;
        }
        & span {
          @apply text-white border-white border-[2px] py-1 px-4 rounded-[6px] hover:text-blue hover:bg-white transition-all duration-300 mt-4 block cursor-pointer;
        }
        & p {
          @apply text-white block py-0 px-4 mb-4 text-base text-center font-semibold;
        }
      }
    }
  }
  &:hover {
    & .card-inner {
      & > span > img {
        @apply scale-125;
      }
      & .card-content {
        & .card-extra {
          @apply opacity-100 h-[60px];
        }
      }
    }
  }
}
.unmissable-card {
  @apply h-full flex flex-col;

  & img {
    @apply w-full h-[186px] object-cover;
    transition: all .3s;
  }
  & .card-content {
    @apply p-4 flex flex-col flex-grow;
    & h3 {
      @apply font-sans text-blue text-[18px] leading-[22px] font-semibold flex-grow;
    }
    & a {
      @apply my-4 text-[14px] text-blue font-semibold underline;
    }
  }
}

.popular {
  @apply max-w-[362px];
  & h3 {
    @apply text-white;
  }
  &.popular-large {
    @apply md:mb-0 md:max-w-[744px] lg:max-w-[671px];
  }
  & div {
    & svg {
      @apply h-[18px] mb-[6px] mx-auto;
    }
    p {
      @apply mt-3;
    }
  }
  & img {
    transition: all 0.6s;
  }
  &:hover {
    & img {
      transform: scale(1.2);
    }
  }
}
.rating {
  & svg {
    @apply inline-block mr-2;
  }
}

.with-map {
  & .card {
    &.landscape {
      & .card-inner {
        @apply md:h-[356px] !important;
        padding: 20px 3% 20px;
      }
    }
    & .card-inner {
      & .properties {
        & span {
          margin-right: 1.5rem !important;
        }
      }
    }
    & .properties-label {
      display: none !important;
    }
    &.hotel {
      & .card-inner {
        @apply md:h-[356px] !important;
      }
      & .gallery {
        @apply w-1/2;

        & .slider-container {
          @apply md:h-[356px];
        }
      }
    }
  }
}

.article-card {
  & h2 {
    @apply text-blue;
  }
  &.standard-article {
    @apply max-w-[443px] break-inside-avoid-page mx-auto mt-0 mb-12 w-full md:w-2/5;
  }
}

.option-card {
  @apply flex relative bg-white flex-row;
  //width: calc(100% + 160px);
  margin-top: 40px;
  .image {
    width: 262px;
    img {
      object-fit: cover;
    }
  }
  .inner {
    @apply flex-grow py-4 flex flex-row items-center;
    .content {
      @apply pl-8 pr-4 h-full;
      h2 {
        @apply font-sans font-semibold p-0 m-0 text-[22px];
      }
    }
    .actions {
      @apply h-full px-4 w-1/4;
      p {
        @apply py-0 m-0;
        > span {
          font-size: 24px;
          font-weight: 600;
          line-height: 32px;
          @apply block;
          > span {
            @apply text-base font-normal;
          }
        }
      }
    }
  }
}
.selectable-dates {
  @apply border border-blue py-3 mr-6 font-serif font-semibold text-base text-blue outline-0;
  > option {
    @apply font-semibold font-serif text-base p-2;
  }
}
.room-img-wrapper {
  & > span {
    @apply max-w-[326px] md:max-w-full !important;
  }
}
.card-gallery {
  .slider-control-centerright {
    @apply !right-0;
  }
  .slider-control-centerleft {
    @apply !left-0;
  }
}