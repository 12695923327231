.discover-intro-wrapper {
  @apply lg:mt-[-30vh] relative lg:w-[90%] p-8 lg:p-20 mr-0 ml-auto bg-grey-100;
  & .discover-intro-inner {
    & h1 {
      @apply w-full xl:w-2/6 pb-4 after:block after:content-[""] after:w-[83px] after:absolute after:h-[2px] after:bg-red after:top-[101.5%] after:left-[28px] relative font-semibold font-display mb-8 text-blue text-4xl lg:text-84 lg:leading-[101px];
      & span {
        @apply font-sans text-xl lg:text-3xl font-normal block mb-[.3rem] lg:leading-[2.7rem];
      }
    }
    & .discover-intro {
      @apply lg:columns-2 xl:w-4/5;
      & p {
        &:first-child {
          @apply mt-0;
        }
        &:last-of-type {
          @apply mb-0;
        }
      }
    }
    & > svg {
      @apply hidden xl:block w-40 absolute right-28 top-1/2 -translate-y-1/2;
    }
  }
}
.discover-content {
  @apply lg:flex lg:flex-row;
  & .column {
    @apply relative lg:w-1/2 px-4 lg:px-8;
    &:first-child {
      @apply mb-8 lg:mb-0;
    }
    & h2 {
      @apply relative text-2xl lg:text-[2.65rem] text-blue mb-8 lg:leading-[60px] pb-4 after:block after:content-[""] after:w-[83px] after:absolute after:h-[2px] after:bg-red after:top-[101.5%] after:left-[28px];
      & span {
        @apply block text-teal-200 font-sans font-semibold text-sm lg:text-base uppercase tracking-wide mb-2 lg:mb-4;
      }
    }
  }
}
.season-tabs {
  @apply sticky z-[11] top-[81px] lg:top-[100px] bg-white flex flex-row flex-nowrap w-full overflow-x-auto;
  & .tab {
    @apply lg:w-1/4 ml-1 flex min-w-[300px] w-full items-center justify-center p-5 relative after:content-[""] after:scale-0 after:absolute after:block after:h-[3px] after:bg-red after:opacity-0 after:transition-all after:duration-300 after:bottom-1;
    & > div {
      @apply ml-3 pl-3 border-l border-l-teal-200;
      & p {
        @apply m-0 text-blue flex flex-row font-serif text-base;
        & strong {
          @apply text-3xl leading-7 font-sans;
        }
        & span {
          @apply font-semibold inline-block ml-1 font-sans;
        }
        &:last-of-type {
          @apply text-[18px] leading-[22px];
        }
      }
    }
    &:after {
      width: calc(100% - 1rem);
    }

    &.active, &:hover {
      @apply after:opacity-100 after:scale-100;
    }
  }
  & button {
    background-color: #ECF7F8 !important;
    opacity: 1 !important;
  }
}
.tab-panel {
  @apply pt-0 relative;
  & > span {
    @apply top-0 w-full lg:absolute !important;
  }
  & > div {
    @apply relative z-10;
  }
  & .tab-title {
    @apply pt-[29px] lg:pt-[89px] max-w-[600px] mb-8;
    & .tab-heading {
      @apply lg:text-white font-normal text-2xl lg:text-5xl lg:leading-[58px] pt-0 px-4 lg:px-0;
      & span {
        @apply text-sm lg:text-base uppercase block mb-2;
      }
    }
  }
  & .tab-content {
    @apply max-w-[920px] mx-auto bg-white flex flex-col;
    & .tab-stats {
      @apply lg:flex w-full flex-row;
      & > p {
        @apply m-1 bg-red text-white p-[35px_16px_25px] lg:w-1/3 text-center text-[14px] leading-[22px] uppercase;
        & span {
          @apply block text-[48px] font-semibold leading-[58px]
        }
      }
    }
    & .tab-info {
      @apply w-full py-[38px] px-[45px];
      & p {
        @apply text-base;
        &:first-child {
          @apply mt-0 text-[18px] uppercase font-semibold;
        }
      }
    }
  }
}
