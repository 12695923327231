.button {
  @apply inline-block text-xs border border-transparent rounded-[3px] font-medium cursor-pointer font-sans text-center p-2 min-w-[85px];
  transition: all .3s;
  &.primary {
    @apply text-white bg-teal-200 border-teal-200 hover:bg-blue;
  }
  & > svg {
    @apply w-[24px] mr-2;
  }
  &.contact {
    @apply w-full md:w-auto mb-2 md:mb-0 flex flex-row items-center justify-center border-blue text-blue font-semibold uppercase;
  }
  &.info {
    @apply flex flex-row items-center border-red bg-red text-white font-semibold uppercase;
  }
}

.info-accordion-button {
  @apply py-4 px-0 border-t border-t-gray-300 text-2xl flex flex-row justify-between items-center outline-0 cursor-pointer text-blue;
  &[aria-expanded="true"] {
    & svg {
      transform: rotate(-90deg);
    }
  }
}
.button-disabled {
  opacity: 0.5;
  cursor: not-allowed !important;
}
.select-button {
  @apply relative border-2 border-teal-200 text-teal-200 rounded-3xl px-4 py-2 font-semibold text-xs text-center w-[90px] indent-[900px] overflow-hidden bg-gray-400;
  &:before {
    @apply absolute content-["✔"] block rounded-full bg-white h-full w-[36px] top-0 left-0;
    transition: left 0.3s;
  }
  &.selected {
    @apply text-white bg-teal-200;
    &:before {
      @apply content-["✔"] text-teal-200 left-[calc(100%_-_36px)];
    }
  }
}
