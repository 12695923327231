.booking-background {
  @apply fixed top-0 left-0 w-full h-full z-[-1];
}
.booking-content {
  @apply relative z-[2] mt-20 mb-20 mx-auto w-full max-w-[695px] bg-white p-12 font-normal;
  & p {
    @apply first-of-type:mt-0 first-of-type:relative first-of-type:after:content-[""] first-of-type:after:block first-of-type:after:w-[83px] first-of-type:after:bg-red first-of-type:after:h-[2px] first-of-type:after:bottom-0 first-of-type:pb-4 first-of-type:after:absolute;
  }
  & h1 {
    @apply text-4xl text-blue;
  }
}
.info-required {
  @apply w-full md:w-auto text-xs border border-transparent rounded-[3px] font-sans text-center p-2 min-w-[85px] flex flex-row items-center justify-center bg-red text-white font-semibold uppercase cursor-pointer;
  & > svg {
    @apply w-[22px] mr-2;
  }
}
.form-row {
  @apply flex flex-row items-center justify-start mt-4;
  & .form-field {
    @apply w-1/2;
    & label {
      @apply text-teal-200 font-semibold block mb-2;
    }
    & input, & textarea, & select {
      @apply border-blue border p-2 outline-none w-[90%];
      &:focus {
        @apply outline-none;
      }
    }
    &.wide {
      @apply w-full;
    }
  }
  & button {
    @apply bg-blue font-semibold text-white min-w-[140px] mx-auto;
  }
}

.guests-disabled {
  opacity: 0.5;
  cursor: not-allowed !important;
}

.trip-details {
  @apply relative;
  z-index: 1;
  background: linear-gradient(180deg, rgba(242, 242, 242, 0) 40%, white 40%);
  & .hero-image {
    height: 300px;
    img {
      height: 100%;
      width: 100%;
    }
  }
  & > div {
    @apply bg-white xl:p-12;
    h2 {
      @apply font-sans text-blue text-3xl font-semibold;
    }
    & .trip-address {
      @apply mt-0;
    }
    & .trip-summary {
      @apply bg-grey-100 p-12;
      & .booking-summary {
        @apply pb-8 mb-8 flex flex-row justify-between items-start flex-wrap border-b-white border-b-2 text-left;
        & h3 {
          @apply text-2xl font-normal font-serif mb-4;
        }
        p {
          @apply mt-0;
        }
        & .booking-info {
          @apply w-[183px] p-4 flex-shrink-0 text-xs bg-white text-blue;
          & .label {
            @apply flex flex-row items-center border border-grey-400 font-semibold mb-3 p-[3px];
            & svg {
              margin: 0 3px 0 2px;
            }
          }
          & .reference {
            @apply block font-semibold text-blue;
          }
          a:not(.button) {
            @apply block mt-2 underline font-semibold text-blue;
          }
        }
      }
      & .options {
        @apply relative flex flex-row my-4;
        & > div {
          @apply mx-4 px-4;
          &:first-child {
            @apply ml-0 pl-0;
          }
          &:last-child {
            @apply mr-0 pr-0 border-l border-l-blue;
          }
          &:only-child {
            @apply border-l-0;
          }
          & .label {
            display: flex;
            font-size: 12px;
            font-weight: 500;
            color: #666;
          }
        }
      }
      & .address-details {
        @apply flex flex-row flex-wrap mb-4;
        & dt {
          @apply font-semibold w-1/3;
        }
        & dd {
          @apply w-1/2;
          a {
            @apply block font-semibold underline mt-2;
          }
        }
      }
      & .telephone-details {
        @apply flex flex-row flex-wrap mb-4;
        & dt {
          @apply font-semibold w-1/3;
        }
        & dd {
          @apply w-1/2;
          a {
            @apply inline font-semibold underline;
          }
        }
      }
      & .room-description {
        margin-top: 0.25rem !important;
        > p {
          margin-top: 0 !important;
        }
      }
    }
  }
}

.booking-info-message {
  ul {
    @apply list-disc my-4 px-8
  }
}
