footer {
  @apply bg-blue text-white relative py-21;
  & .scroll-to-top {
    @apply absolute z-10 left-[15%] top-[-31px] cursor-pointer;
    width: 3.875rem;
    height: 3.875rem;
    & svg {
      width: 100%;
      max-height: 100%;
    }
  }
  & .logo {
    @apply w-[80px] md:w-full relative mx-auto;
    &:after {
      display: block;
      content: "";
      width: 51px;
      height: 1px;
      background: #fff;
      position: absolute;
      bottom: -24px;
      left: 50%;
      transform: translateX(-50%);
    }
    & svg {
      max-height: 100%;
    }
  }
  & .column {
    @apply mx-4;
  }
  & .copyright {
    @apply mt-12 mb-8 md:mb-0 md:mt-8 font-medium text-center md:text-left;
    font-size: .8rem;
  }
  & .footerNav {
    @apply mx-4;
    & ul {
      @apply md:columns-2 lg:columns-3 text-base mx-auto text-center md:text-left;
      & li {
        @apply mb-4;
      }
    }
  }
  & .social ul li {
    @apply mt-6 md:mt-0 mb-4 text-base;
    & a {
      @apply flex flex-row items-center text-white justify-center md:justify-start;
      & svg {
        @apply text-teal-100 w-[24px] h-[24px] block !important;
      }
      & span {
        @apply block ml-3;
      }
    }
  }
}
@screen md {
  .logo {
    &:after {
      @apply hidden;
    }
  }
}
