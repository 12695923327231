.tabs-wrapper {
  @apply p-1 flex flex-row overflow-x-auto max-w-full bg-grey-100;
  & .tab {
    @apply bg-transparent transition-all duration-300;
    & > button {
      @apply relative text-sm leading-[22px] min-w-[175px] py-[18px] block bg-transparent font-sans font-normal text-blue;
      &:after {
        @apply absolute content-[""] block left-[2px] bottom-[4px] h-[4px] w-[calc(100%_-_4px)] bg-transparent transition-all duration-300 scale-0;
      }
      &[disabled] {
        opacity: 0.3;
      }
    }
    &.active {
      @apply bg-white;
      & > button {
        @apply opacity-100;
        &:after {
          @apply bg-red scale-100;
        }
      }
    }

  }
}
