.slider-control-centerleft {
  top: 100% !important;
  transform: translateY(0) !important;
  left: 30% !important;
}
.slider-control-centerright {
  top: 100% !important;
  transform: translateY(0) !important;
  right: 30% !important;
}
.passions-carousel {
  & .slider-control-centerleft {
    top: 50% !important;
    transform: translateY(0) !important;
    left: 0 !important;
  }
  & .slider-control-centerright {
    top: 50% !important;
    transform: translateY(0) !important;
    right: 0 !important;
  }
}
.carousel-button {
  color: #00243F;
  width: 46px;
  border: none;
  cursor: pointer;
  height: 46px;
  display: block;
  outline: none;
  padding: 0;
  z-index: 2;
  font-size: 46px;
  background: transparent;
  line-height: 0;
  &[disabled] {
    cursor: default;
    opacity: 0;
  }
}
.popular-carousel {
  & .slider-control-centerright {
    right: 50px !important;
  }
  & .slider-control-centerleft {
    left: 20px !important;
  }
}
.slider-control-bottomcenter {
  bottom: -28px !important;
}
@screen lg {
  .slider-control-centerleft {
    top: 50% !important;
    transform: translateY(-50%) !important;
    left: -62px !important;
  }

  .slider-control-centerright {
    top: 50% !important;
    transform: translateY(-50%) !important;
    right: -62px !important;
  }
}
@screen xl {
  .passions-carousel {
    & .slider-control-centerleft {
      left: -62px !important;
    }
    & .slider-control-centerright {
      right: -62px !important;
    }
  }
}
