.form {
    .form-error {
        @apply text-lg font-bold text-red;
    }
    fieldset {
        @apply mb-4 last:mb-0;
        legend {
            @apply font-serif text-lg mb-2;
            span {
                @apply block uppercase font-sans text-xs font-normal;
            }
        }
    }
    .field-row {
        @apply flex flex-row mb-4 last:mb-0 flex-wrap gap-y-4;
        .field {
           input[type="text"], input[type="number"], textarea {
                @apply border border-blue/50 outline-none w-[calc(100%_-_1rem)] px-2 py-1 placeholder:uppercase;
                &.error {
                    @apply border-red placeholder:text-red bg-red/10;
                }
            }
            .checkbox {
                @apply flex flex-row items-center cursor-pointer;
                span {
                    @apply w-6 h-6 border border-blue/50 outline-none bg-white relative mr-2 block;
                    &:after {
                        content: "";
                        position: absolute;
                        display: none;
                    }
                }
                input[type="checkbox"] {
                    @apply opacity-0 absolute;
                    &:checked ~ span {
                        @apply bg-blue;
                        &:after {
                            display: block;
                            content: "";
                            position: absolute;
                            left: 7px;
                            top: 3px;
                            width: 8px;
                            height: 12px;
                            border: solid white;
                            border-width: 0 3px 3px 0;
                            transform: rotate(45deg);
                        }
                    }
                }
            }
            &:last-child {
                input[type="text"], input[type="number"], textarea {
                    @apply w-full;
                }
            }
        }
    }
    .actions {
        @apply absolute w-full -bottom-4 left-0;
        button {
            @apply bg-teal-200 text-white py-2 px-4 font-semibold mx-auto block rounded;
        }
    }
    &.concierge-form {
        @apply bg-gold-50 p-8 mt-4 relative;
    }
}
.checkout-summary {
    @apply min-h-screen bg-grey-100;
}
.checkout-links {
    @apply flex flex-row flex-wrap justify-between py-8 pr-14;
}